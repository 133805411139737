<template>
	<div class="commandContainer" ref="commandContainer">
		<div class="container2">
			<div class="blockLeftTitle2">
				<div class="blockLeftTitle2__left">
					<div class="mainTitle2">
						<p class="h2 animationBlockFadeUp" v-html="content.title" v-if="content.title"></p>
						<p class="color-gray mainTitle2--desc animationBlockFadeUp" v-html="content.desc" v-if="content.desc"></p>
						<router-link to="/vacancies/" class="link animationBlockFadeUp mt32">Стать частью команды</router-link>
					</div>
				</div>
				<div class="blockLeftTitle2__right">
					<img class="animationBlockFadeUp" :src="content.image" alt="" :class="{mb60: content.percents}">
					<Percents :content="content.percents" v-if="content.percents"></Percents>
				</div>
			</div>
		</div>
	</div>
</template>


<style lang="sass">
	@import '@/assets/new_sass/Command'
</style>

<script>
	export default {
		props: ['content'],
		data: () => ({
		}),
		mounted(){
		},
		methods: {
		},
		components: {
			Percents: () => import('@/components/new/Percents'),
		}
	}
</script>
